<template>
    <div>
        <b-modal id="bv-abb_form" scrollable hide-footer size="md" title="GBB Claim Request Form">
            <div class=" ">
                <h5 style="font-size: 18px;font-weight: 800;">
                    Customer Details
                </h5>
                <div class="d-flex">
                    <div class="p-2 ">
                        <label>Customer Name</label>
                        <b-form-input placeholder="Customer Name" v-model="name" class=" inp"></b-form-input>
                    </div>
                    <div class="p-2">
                        <label>Email</label>
                        <b-form-input placeholder="Email" :state="emailState" v-model="email"
                            class="inp"></b-form-input>
                    </div>
                </div>
                <div class="p-2">
                    <label>Mobile Number</label>
                    <b-input-group style="width: fit-content;">
                        <template #prepend>
                            <b-input-group-text class="phone-code">{{ countryCode }}</b-input-group-text>
                        </template>
                        <b-form-input type="text"
                            onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                            max="999999999" placeholder="5XXXXXXXXXXX" :state="mobileState" v-model="number"
                            class="input-login inp" :formatter="mobileFormatter"></b-form-input>
                    </b-input-group>
                </div>
                <h5 class="mt-3" style="color: #3E3D37;">
                    Invoice Details
                </h5>
                <div class="d-flex">
                    <div class="p-2">
                        <label>Invoice Number (optional)</label>
                        <b-form-input placeholder="Invoice Number" v-model="invoice" class=" inp"></b-form-input>
                    </div>
                    <div class="p-2">
                        <label>Invoice Date</label>
                        <b-form-input id="date" v-model="date" @change="check_date" type="date"
                            class=" inp"></b-form-input>
                    </div>
                </div>
                <div class="p-2">
                    <label>Unique ID</label>
                    <b-form-input placeholder="Unique ID" v-model="unique_id" class=" inp"></b-form-input>
                </div>

                <div class="p-2">
                    <label style="font-weight: 800;font-size: small">Upload the original purchase invoice</label>
                    <image-uploader :preview="false" :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                        style="max-width: 150px;" capture="environment" :debug="1" :maxWidth="720" doNotResize="gif"
                        :autoRotate="true" outputFormat="verbose" @input="setImage">

                    </image-uploader>
                </div>
                <div class="p-2 ">
                    <label style="font-weight: 800;font-size: small">Upload proof of exchange by an authorized apple
                        service
                        center.</label>
                    <image-uploader :preview="false" :className="['fileinput', { 'fileinput--loaded': hasImage2 }]"
                        style="max-width: 150px;" capture="environment" :debug="1" :maxWidth="720" doNotResize="gif"
                        :autoRotate="true" outputFormat="verbose" @input="setImage2">

                    </image-uploader>
                </div>
                <div class="d-flex">
                    <div class="p-2">
                        <label>Category</label>
                        <b-form-select id="input-3" v-model="category_selected" @change="Get_Brand" class=" inp"
                            required>
                            <b-form-select-option :value="null">Please select category</b-form-select-option>
                            <b-form-select-option v-for="i in category_list" :value="i.value">{{ i.text
                                }}</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div class="p-2">
                        <label>Brand</label>
                        <b-form-select id="input-3" v-model="brand_selected" @change="Get_SKU" class=" inp" required>
                            <b-form-select-option :value="null">Please select brand</b-form-select-option>
                            <b-form-select-option v-for="i in brand_list" :value="i.value">{{ i.text
                                }}</b-form-select-option>
                        </b-form-select>
                    </div>
                </div>
                <div class="p-2">
                    <label>SKU Model</label>
                    <b-form-select id="input-3" v-model="model_selected" class=" inp" required>
                        <b-form-select-option :value="null">Please select model</b-form-select-option>
                        <b-form-select-option v-for="i in sku_list" :value="i.value">{{ i.text }}</b-form-select-option>
                    </b-form-select>
                </div>

                <div class="p-2">
                    <div class="d-flex justify-content-between">
                        <label>IMEI</label>
                        <div id="popover-target-1">
                            <img src="../assets//info.png" width="24px" />
                        </div>
                    </div>
                    <b-popover target="popover-target-1" triggers="hover" placement="top">
                        <template #title>Find Your Mobile IMEI</template>
                        Dial <b>*#06#</b> on your phone to instantly display the IMEI number
                    </b-popover>
                    <b-form-input placeholder="IMEI" title="Dial *#06# to get IMEI"
                        onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                        max="999999999" :state="imeiState" v-model="imei" class="inp"
                        :formatter="imeiFormatter"></b-form-input>
                </div>
                <div class="p-2">
                    <label>Invoice Amount (Excluding VAT)</label>
                    <b-input-group>
                        <template #prepend>
                            <b-input-group-text class="phone-code">{{ currency }}</b-input-group-text>
                        </template>
                        <b-form-input type="text"
                            onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                            max="999999999" placeholder="0.00" :state="mobileState" v-model="purchase_amount"
                            class="input-login inp" :formatter="amountFormatter"></b-form-input>
                    </b-input-group>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <b-button class="" variant="success" style="border-radius: 0%;font-size: larger;"
                        @click="upload_invoice()">Confirm
                        Claim</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="bv-display_id" scrollable hide-footer size="md" title="Unique Id to claim device">
            <h5 style="font-size: 18px;font-weight: 800;">
                UNIQUE ID: {{ this.quote_request }}
            </h5>
        </b-modal>
    </div>
</template>
<script>
import api from '../api'
import ImageUploader from 'vue-image-upload-resize'
import { BIconArrowLeftCircleFill, BIconArrowLeft } from 'bootstrap-vue'

export default {
    name: 'WithoutSignin',
    data() {
        return {
            selectedCardId: null,
            fields: [{
                key: 'Time (Months)',
                sortable: true,

            },
            {
                key: 'Price Retained Condition 1',
                sortable: true,

            },
            {
                key: 'Price Retained Condition 2',
                sortable: true,
            },
            {
                key: 'Price Retained Condition 3',
                sortable: true,
            }],
            category_selected: null,
            brand_selected: null,
            track_data: null,
            selected: [],
            return_date: null,
            file1: null,
            file2: null,
            model_selected: null,
            sku_model: null,
            sku_price: null,
            invoice: '',
            imei: '',
            unique_id: '',
            sku: '',
            model: '',
            condition: '',
            amount: '',
            name: '',
            email: '',
            number: '',
            date: '',
            hasImage: false,
            image: null,
            hasImage2: false,
            image2: null,
            form: {
                state: null,
                country: null

            },
            states: [{ text: 'Select City', value: null }, 'Abu Dhabi', 'Ajman', 'Dubai', 'Sharjah', 'Umm al-Quwain',
                'Fujairah', 'Ras al-Khaimah'],
            country: [{ text: 'Select Condition', value: null }, 'Condition 1', 'Condition 2', 'Condition 3'],
            category_list: [],
            brand_list: [],
            sku_list: [],
            service_list: null,
            purchase_amount: 0,
            service_selected: null,
            quote_request: null,
            service_amount: 0,
            items: [],
            currency: ''


        }
    },
    components: {
        BIconArrowLeft,
        BIconArrowLeftCircleFill,
        ImageUploader
    },
    computed: {
        countryCode: function () {
            return this.$store.state.user.contury_code
        },
        nameState: function () {
            if (this.name.length) {
                return this.name.length > 2
            } else {
                return null
            }
        },
        addState: function () {
            if (this.useradd.length) {
                return this.useradd.length > 8
            } else {
                return null
            }
        },
        emailState: function () {
            if (this.email.length) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return re.test(String(this.email).toLowerCase())
            } else {
                return null
            }
        },
        mobileState: function () {
            if (this.number.length) {
                return this.number.length === 9
            } else {
                return null
            }
        },
        imeiState: function () {
            if (this.imei.length) {
                return this.imei.length > 14
            } else {
                return null
            }
        },


    },
    methods: {
        isSelected(id) {
            return this.selectedCardId === id;
        },
        hideModal() {
            this.$bvModal.hide('bv-display_id')
        },
        showModal() {
            this.$bvModal.hide('bv-abb_form')
            this.$bvModal.show('bv-display_id')

        },
        Get_Category: function () {
            const inputs = {
                'user_id': this.$store.state.user.uid,
                'user_token': this.$store.state.user.user_token,
                'token_data': this.$store.state.user.token_data

            }
            api.request(process.env.VUE_APP_ABB_CATEGORY, inputs).then((response) => {
                const data = response.data
                if (data.result) {
                    this.category_list = data.result
                }
            }).catch((error) => {
                this.errormessage = error
            })
        },
        Get_Brand: function () {
            const inputs = {
                'user_id': this.$store.state.user.uid,
                'user_token': this.$store.state.user.user_token,
                'token_data': this.$store.state.user.token_data,
                'category_id': this.category_selected

            }
            this.brand_selected = null;
            api.request(process.env.VUE_APP_ABB_BRAND, inputs).then((response) => {
                const data = response.data
                if (data.result) {
                    this.brand_list = data.result
                }
            }).catch((error) => {
                this.errormessage = error
            })
        },
        Get_SKU: function () {
            const inputs = {
                'user_id': this.$store.state.user.uid,
                'user_token': this.$store.state.user.user_token,
                'token_data': this.$store.state.user.token_data,
                'category_id': this.category_selected,
                'brand_id': this.brand_selected


            }
            api.request(process.env.VUE_APP_ABB_SKU, inputs).then((response) => {
                const data = response.data
                if (data.result) {
                    this.sku_list = data.result
                    this.currency = data.result[0].currency
                }
            }).catch((error) => {
                this.errormessage = error
            })
        },
        Get_Service_List: function () {
            let currentDate = new Date().toJSON().slice(0, 10);

            const inputs = {
                'user_id': this.$store.state.user.uid,
                'user_token': this.$store.state.user.user_token,
                'token_data': this.$store.state.user.token_data,
                'sku_id': this.model_selected,
                'current_date': currentDate


            }
            api.request(process.env.VUE_APP_SERVICE, inputs).then((response) => {
                const data = response.data
                if (data.result) {
                    this.service_list = data.result
                }
            }).catch((error) => {
                this.errormessage = error
            })
        },
        selected_service: function (service_id, price) {
            // this.service_selected = 1
            this.service_selected = this.service_list.find(x => x.id === service_id)
            this.service_amount = price;
            this.selectedCardId = service_id;

        },
        handle_okay: function () {
            this.$router.push({ name: 'ABB_Track', params: { 'id': this.track_data } })
        },
        handle_cancel: function () {
            window.location.reload()
        },
        setImage: function (file) {
            this.hasImage = true
            this.image = file.dataUrl.replace('data:', '').replace(/^.+,/, '')
            // console.log('Info', file.info)
            // console.log('Info', file.dataUrl)
            // console.log('Exif', file.exif)
        },
        setImage2: function (file) {
            this.hasImage2 = true
            this.image2 = file.dataUrl.replace('data:', '').replace(/^.+,/, '')
            // console.log('Info', file.info)
            // console.log('Info', file.dataUrl)
            // console.log('Exif', file.exif)
        },

        mobileFormatter: function (e) {
            return String(e).substring(0, 9)
        },
        amountFormatter: function (e) {
            return String(e).substring(0, 4)
        },

        imeiFormatter: function (e) {
            return String(e).substring(0, 15)
        },
        upload_invoice() {
            if (this.unique_id.length < 3) {
                this.$bvToast.toast('We are not able to process this request right now. Please write the Unique ID', {
                    title: 'Unique ID not Found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            if (this.imei.length < 15) {
                this.$bvToast.toast('We are not able to process this request right now. Please Enter the Device IMEI.', {
                    title: 'Device IMEI not Found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            if (this.date.length < 4) {
                this.$bvToast.toast('We are not able to process this request right now. Please select the invoice date.', {
                    title: 'Invoice date not found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            if (this.model_selected === null) {
                this.$bvToast.toast('We are not able to process this request right now. Please select the SKU Model.', {
                    title: 'SKU Model not Found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            if (this.name.length < 3) {
                this.$bvToast.toast('We are not able to process this request right now. Please enter the Customer Name.', {
                    title: 'Customer Name not Found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (!re.test(String(this.email).toLowerCase()) || this.email.length < 12) {
                this.$bvToast.toast('We are not able to process this request right now. Please enter the vaild email.', {
                    title: 'Email Not Found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            if (this.number.length < 9) {
                this.$bvToast.toast('We are not able to process this request right now. Please Enter the Mobile Number', {
                    title: 'Mobile Number not found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            if (!this.hasImage) {
                this.$bvToast.toast('We are not able to process this request right now. Please upload the Invoice Image.', {
                    title: 'Invoice Image not Found',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            if (this.purchase_amount == 0) {
                this.$bvToast.toast('We are not able to process this request right now. Please Enter the Purchase Amount', {
                    title: 'Purchase Amount is missing',
                    variant: 'danger',
                    toaster: 'b-toaster-top-right'
                })
                return
            }
            const program_id = this.sku_list.find(item => item.value === this.model_selected);
            const inputs = {
                'user_id': this.$store.state.user.uid,
                'user_token': this.$store.state.user.user_token,
                'token_data': this.$store.state.user.token_data,
                'invoice': this.unique_id,
                'imei': this.imei,
                'date': this.date,
                'model': this.model_selected,
                'customer': this.name,
                'purchase_amount': this.purchase_amount,
                'service_amount': this.service_amount,
                'unique_id': this.unique_id,
                'program_id': program_id.program_id,
                'email': this.email,
                'number': this.number,
                'user': this.$store.state.user.id,
                'attachment': this.image || '',
                'type': 'png',
                'file_type': 'invoice',
                'attachment2': this.image2 || '',
                'type2': 'png',
                'file_type2': 'apple_exchange'

            }
            api.request(process.env.VUE_APP_CREATE_QUOTE, inputs).then((response) => {
                const data = response.data
                if (response.data.result && response.data.result.length > 0) {
                    this.quote_request = data.result
                    // const dataToSendBack = 'Data from request_abb.vue';
                    this.$bvModal.hide('bv-abb_form')

                    setTimeout(() => {
                        this.$emit('updateData', data.result);
                    }, 1000);


                    // this.showModal()
                    // this.$router.push({ name: 'ABB_Onboarding_Track', params: { 'id': this.quote_request } })

                } else if (response.data.result.error) {
                    this.$bvToast.toast(response.data.result.error, {
                        title: 'Something went wrong',
                        variant: 'danger',
                        toaster: 'b-toaster-top-right'
                    })
                } else {
                    this.$bvToast.toast('We are not able to process this request right now. Please try again', {
                        title: 'Something went wrong',
                        variant: 'danger',
                        toaster: 'b-toaster-top-right'
                    })
                }
            }).catch((error) => {
                this.errormessage = error
            })
        }
    },
    mounted() {
        this.Get_Category()
    }
}
</script>

<style scoped>
.payment-method:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    background-color: green !important;
    border-color: green !important;
    color: white !important;
}

.selected-card {
    border: 2px solid #3FAF2B;
}

.payment-method {
    border-color: rgb(0, 0, 0) !important;
    color: rgb(0, 0, 0) !important;
    border-radius: 0px;
}

.payment-method:hover {
    background-color: transparent !important;
    border-color: green !important;
    color: green !important;
}

.btn-secondary {
    color: rgb(0, 0, 0);
    background-color: transparent;
    border-color: #6c757d;
}

.inp {
    height: 40px;
    border-radius: 0px;

}

.outer_layer {
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.back {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.b-icon.bi {
    height: 45px;
    width: 45px;
    fill: #bbb;
}

.register-wraper {
    max-width: 300px;
    margin: 0 auto;
    padding: 10px 15px;
    text-align: center;
}

.otp-inputnew {
    width: 45px;
    height: 40px;
    padding: 5px;
    margin: 0 10px !important;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-style: dashed;
    text-align: center;
    margin-right: 50px;
}

/* Background colour of an input field with value */
.otp-inputnew.is-complete {
    background-color: #e4e4e4;
}

.otp-inputnew::-webkit-inner-spin-button,
.otp-inputnew::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.otp-title {
    color: #3FAF2B;
    font-weight: bold;
    font-size: 24px !important;
}

/* .card_selection :hover{
    background-color: #3FAF2B;
    color: #fff;
} */
</style>